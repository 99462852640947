<template>
  <div>
    <confirmation ref="Confirmation"></confirmation>
    <datatable-heading
      ref="DatatableHeading"
      :title="$t('pages.navigator-user-logins')"
      :changePageSize="changePageSize"
      :searchChange="_.debounce(searchChange, 500)"
      :from="from"
      :to="to"
      :total="total"
      :perPage="perPage"
      :tableFields="fields"
      @filterChange="filterChange"
    >
    </datatable-heading>
    <b-overlay :show="gridLoading" variant="transparent" blur="5px" opacity="1">
      <b-row>
        <b-colxx xxs="12">
          <b-card>
            <vuetable
              ref="vuetable"
              track-by="id"
              :api-url="apiBase"
              :query-params="makeQueryParams"
              :per-page="perPage"
              :reactive-api-url="false"
              :fields="fields"
              :css="css.table"
              pagination-path
              :row-class="onRowClass"
              @vuetable:pagination-data="onPaginationData"
              @vuetable:row-clicked="rowClicked"
              @vuetable:cell-rightclicked="rightClicked"
              @vuetable:load-error="handleLoadError"
              @vuetable:cell-clicked="cellclicked"
              @vuetable:loading="onVuetableLoading"
              @vuetable:loaded="onVuetableLoaded"
            >
              <template slot="emailConfirmed" slot-scope="props">
                {{props.rowData.emailConfirmed ? 'True' : 'False'}}
              </template>
              <template slot="companies" slot-scope="props">
                <read-more-list :linesToShow="2" separator="," :itemsInLine="1" :listText="props.rowData.companies" />
              </template>
              <template slot="activeDeactiveTemplate" slot-scope="props">
                <div style="display:flex;justify-content:center;align-items;center;">
                  <ToggleSwitch
                    v-on:input.self="flipActive(props.rowData)"
                    v-model="props.rowData.active"
                    :emitOnMount="false"
                    theme="custom"
                    class="vue-switcher-small"
                    color="primary"
                    :id="'toggle-' + props.rowData.id"
                    v-b-tooltip.hover.left
                    :title="`${props.rowData.active ? 'Deactivate' : 'Activate'} ${props.rowData.userName}`"
                  />
                </div>
            </template>
            </vuetable>
          </b-card>
          <vuetable-pagination-bootstrap
            class="mt-4"
            ref="pagination"
            @vuetable-pagination:change-page="onChangePage"
          />
        </b-colxx>
      </b-row>
    </b-overlay>
    <v-contextmenu ref="contextmenu">
      <v-contextmenu-item @click="onContextMenuAction('view_companies')">
        <i class="simple-icon-arrow-right-circle" />
        <span>View Companies</span>
      </v-contextmenu-item>
      <v-contextmenu-item @click="onContextMenuAction('lock_unlock')">
        <i :class="(currentSelectedItem && currentSelectedItem.lockedOut) ? 'simple-icon-lock-open' : 'simple-icon-lock'" />
        <span>{{(currentSelectedItem && currentSelectedItem.lockedOut) ? 'Unlock' : 'Lock'}}</span>
      </v-contextmenu-item>
      <v-contextmenu-item @click="onContextMenuAction('resend_verification')">
        <i class="simple-icon-reload" />
        <span>Resend Verification</span>
      </v-contextmenu-item>
      <v-contextmenu-item v-if="canImpersonate" @click="onContextMenuAction('impersonate_user')">
        <i class="simple-icon-user-unfollow" />
        <span>Impersonate</span>
      </v-contextmenu-item>
    </v-contextmenu>
  </div>
</template>
<script>
import Confirmation from '../../../../components/Common/Confirmation.vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationBootstrap from '../../../../components/Common/VuetablePaginationBootstrap'
import { BeaconapiUrl } from '../../../../constants/config'
import DatatableHeading from '../../../../containers/datatable/DatatableHeading'
import CssTableConfig from '../../../../constants/VuetableBootstrapconfig'
import ApplicationUserMixin from '../../../../mixins/ApplicationUserMixin.vue'
import { mapMutations } from 'vuex'
import moment from 'moment/moment.js'
import { mutations } from '../../../../store/FilterMenu'
import Switches from 'vue-switches'
import ReadMoreList from '../../../../components/Listing/ReadMoreList.vue'

export default {
  props: ['companyID'],
  components: {
    Vuetable,
    'vuetable-pagination-bootstrap': VuetablePaginationBootstrap,
    'datatable-heading': DatatableHeading,
    Confirmation,
    'read-more-list': ReadMoreList,
    ToggleSwitch: Switches
  },
  mixins: [ApplicationUserMixin],
  data () {
    var _idfield = 'id'
    return {
      isLoad: false,
      apiBase: `${BeaconapiUrl}/api/logins/table`,
      sort: '',
      page: 1,
      perPage: 50,
      search: '',
      filters: [],
      from: 0,
      to: 0,
      total: 0,
      lastPage: 0,
      items: [],
      selectedItems: [],
      idfield: _idfield,
      css: CssTableConfig,
      gridLoading: false,
      fields: [
        {
          name: _idfield,
          sortField: _idfield,
          title: 'ID',
          titleClass: '',
          dataClass: 'text-muted',
          width: '9%'
        },
        {
          name: 'userName',
          sortField: 'userName',
          title: 'User Name',
          titleClass: '',
          dataClass: 'text-muted',
          width: '15%',
          filterType: 'text'
        },
        {
          name: 'firstName',
          sortField: 'firstName',
          title: 'First Name',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%',
          filterType: 'text'
        },
        {
          name: 'lastName',
          sortField: 'lastName',
          title: 'Last Name',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%',
          filterType: 'text'
        },
        {
          name: 'createDate',
          sortField: 'createDate',
          title: 'Create Date',
          titleClass: '',
          dataClass: 'text-muted',
          width: '12%',
          callback: this.formatDate,
          filterType: 'date'
        },
        {
          name: 'lockedOut',
          sortField: 'lockedOut',
          title: 'Locked Out',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%',
          filterType: 'bool'
        },
        {
          name: '__slot:emailConfirmed',
          sortField: 'emailConfirmed',
          title: 'Email Confirmed',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%',
          filterType: 'bool'
        },
        {
          name: '__slot:companies',
          sortField: 'companies',
          title: 'Company ID',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%',
          filterType: 'text'
        },
        {
          name: '__slot:activeDeactiveTemplate',
          sortField: 'active',
          title: 'Status',
          titleClass: 'center aligned text-center',
          dataClass: 'center aligned',
          width: '10%'
        }
      ],
      currentSelectedItem: null
    }
  },
  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setAlertMessage',
      'setInfoMessage',
      'appendInfoMessage'
    ]),
    makeQueryParams (sortOrder, currentPage, perPage) {
      this.selectedItems = []
      return sortOrder[0]
        ? {
          sort: sortOrder[0]
            ? sortOrder[0].field + '|' + sortOrder[0].direction
            : '',
          page: currentPage,
          per_page: this.perPage,
          search: this.search,
          filters: JSON.stringify(this.filters)
        }
        : {
          page: currentPage,
          per_page: this.perPage,
          search: this.search,
          filters: JSON.stringify(this.filters)
        }
    },
    onVuetableLoading () {
      this.gridLoading = true
    },
    onVuetableLoaded () {
      this.gridLoading = false
    },
    onPaginationData (paginationData) {
      this.from = paginationData.from
      this.to = paginationData.to
      this.total = paginationData.total
      this.lastPage = paginationData.last_page
      this.items = paginationData.data
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    changePageSize (perPage) {
      this.perPage = perPage
      this.refreshVueTable()
    },
    searchChange (val) {
      this.search = val
      this.refreshVueTable()
    },
    filterChange (filters) {
      this.filters = filters
      this.refreshVueTable()
    },
    handleLoadError (response) {
      this.setDangerMessage(response)
    },
    onRowClass (dataItem, index) {
      var outputclass = ''
      if (this.selectedItems.includes(dataItem[this.$data.idfield])) {
        outputclass = 'selected'
      }
      return outputclass
    },
    cellclicked (dataitem, field, event) {},
    rightClicked (dataItem, field, event) {
      event.preventDefault()
      this.rightClickedItem = dataItem
      this.currentSelectedItem = dataItem
      const itemId = dataItem[this.$data.idfield]
      if (!this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = [itemId]
      }
      this.$refs.contextmenu.show({ top: event.pageY, left: event.pageX })
      this.selectedItems = this.$refs.vuetable.selectedTo
    },
    rowClicked (dataItem, event) {
      const itemId = dataItem[this.$data.idfield]
      this.selectedItems = this.$refs.vuetable.selectedTo
    },
    refreshVueTable () {
      this.$refs.vuetable.refresh()
    },
    onContextMenuAction (action) {
      switch (action) {
        case 'view_companies': {
          this.$router.push({
            name: 'ViewCompanies',
            query: { loginid: this.selectedItems[0] }
          })
          break
        }
        case 'lock_unlock': {
          this.$refs.Confirmation.show({
            title: `${this.currentSelectedItem.lockedOut ? 'Unlock' : 'Lock'}`,
            message: `Are you sure you want to ${this.currentSelectedItem.lockedOut ? 'Unlock' : 'Lock'} ${this.currentSelectedItem.userName}?`,
            okButton: `${this.currentSelectedItem.lockedOut ? 'Unlock' : 'Lock'}`
          }).then(result => {
            if (result) {
              this.lockOrUnlockItems(this.currentSelectedItem.id, this.currentSelectedItem.lockedOut)
            }
          })
          break
        }
        case 'resend_verification': {
          this.resendValidationItem(this.currentSelectedItem.userName)
          break
        }
        case 'impersonate_user': {
          this.impersonateUser(this.currentSelectedItem.userName)
          break
        }
        default:
          break
      }
    },
    async lockOrUnlockItems (userId, isLocked) {
      await this.lockOrUnlockUserLogin(userId, isLocked).then(response => {
        this.refreshVueTable()
      })
    },
    async resendValidationItem (email) {
      await this.resendValidation(email).then(response => {
        this.refreshVueTable()
      })
    },
    async impersonateUser (email) {
      await this.impersonate(email)
    },
    formatDate (value) {
      if (value === null) {
        return ''
      } else {
        return moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY')
      }
    },
    flipActive (template) {
      this.$refs.Confirmation.show({
        title: `${template.active ? 'Activate' : 'Deactivate'} User Account`,
        message: `Are you sure you want to ${template.active ? 'activate' : 'deactivate'} ${template.userName}?`,
        okButton: 'Yes'
      }).then(async (result) => {
        if (result) {
          this.setInfoMessage('')
          var payload = {
            aspNetUserID: template.id,
            active: template.active
          }
          await this.activeDeactivateUser(payload)
            .then(response => {
              this.refreshVueTable()
            })
            .catch(err => {
              if (err.response) {
                // client received an error response (5xx, 4xx)
                this.setDangerMessage(err.message)
              } else if (err.request) {
                // client never received a response, or request never left
                this.setDangerMessage(err.message)
              } else {
                // anything else
                this.setDangerMessage(err.message)
              }
            })
        } else {
          this.refreshVueTable()
        }
      })
    }
  },
  mounted () {
    const urlParams = new URL(location.href).searchParams
    if (urlParams.toString().length === 0) {
      mutations.clearFilters()
      mutations.setFilterByColumn('companies', this.companyID.toString())
      // hold this firing filter event for a second to the table rerender the filtered data
      setTimeout(() => {
        this.$refs.DatatableHeading.fireFilterEvent()
      }, 1000)
      // ------------------
    }
  },
  computed: {
    canImpersonate () {
      var permissions = JSON.parse(localStorage.getItem('userPermissions'))
      return permissions.some(p => p.permissionName === 'Login Impersonation')
    }
  }
}
</script>
